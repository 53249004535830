import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EncuestaAgradecimientoForm from "./templates/encuesta-agradecimiento"

const EncuestaAgradecimiento = ({ location }) => (
  <Layout location={location}>
    <SEO title="Encuesta de satisfaccion" />
    <EncuestaAgradecimientoForm />
  </Layout>
)

export default EncuestaAgradecimiento